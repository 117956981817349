import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      themes: {
          light: {
              primary: '546E7A',
              'primary-red': '#c91f1a',
              'primary-second': '#1c1c1b',
              primarytext: '#7f8080',
          },
      },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
