import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'
import Contabilidad from './contabilidad'
import ActivosFijos from './activoFijo'
import Configuraciones from './configuracion'
import CuentasPorPagar from './cuentasPorPagar'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '*',
    name: 'Error',
    component: () => import('../views/Error.vue') 
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue') 
  },
  {
    path: '/Home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    children: Contabilidad.contabilidad.concat(ActivosFijos.activosFijos, Configuraciones.configuraciones, CuentasPorPagar.cuentasPorPagar)
  }
]
// console.log(baseRoutes)
const routes = baseRoutes

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  const res = await Store.dispatch('verifyToken')

  if (to.name !== 'Login') {
    if (res.state) {
      if (localStorage.getItem('rowKey')){
        console.log('next y res ok')
        next()
      } else {
        router.push({name:'Login'})
      }
    } else {
      // Vue.prototype.$message({
      //   showClose: true,
      //   message: `Oops, ${res.message + " Token invalido hacer Login nuevamente"}`,
      //   type: "error",
      // })
      // console.log('error aqui tambien router')
      Store.commit('setDestroyTokens')
      next()
    }
  } else {
    if (res.state) {
      // console.log('me fui al home')
      router.push({name:'Home'})
    } else {
      // console.log('es diferente de login y no hay res false, next por aqui')
      next()
    }
  }
})

export default router
