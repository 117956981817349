
const catalogos = [
  {
    path: '/CuentasContables',
    name: 'CuentasContables',
    component: () => import(/* webpackChunkName: "CuentasContables" */ '../views/contabilidad/Parametros/CuentasContables'),
    meta: {
      titulo: 'Cuentas Contables'
    }
  },
  {
    path: '/CabysCuenta',
    name: 'CabysCuenta',
    component: () => import(/* webpackChunkName: "CabysCuenta" */ '../views/contabilidad/Parametros/CabysCuenta'),
    meta: {
      titulo: 'Cabys Cuenta'
    }
  },
  {
    path: '/CCyDeptos',
    name: 'CCyDeptos',
    component: () => import(/* webpackChunkName: "CCyDeptos" */ '../views/contabilidad/Parametros/CCyDeptos'),
    meta: {
      titulo: 'Centros de costo, Periodos Fiscales y Departamentos'
    }
  },

  {
    path: '/CCyDeptos2',
    name: 'CCyDeptos2',
    component: () => import(/* webpackChunkName: "CCyDeptos" */ '../views/contabilidad/Parametros/CCyDeptos'),
    meta: {
      titulo: 'Centros de costo, Periodos Fiscales y Departamentos'
    }
  },

  {
    path: '/CCyDeptos3',
    name: 'CCyDeptos3',
    component: () => import(/* webpackChunkName: "CCyDeptos" */ '../views/contabilidad/Parametros/CCyDeptos'),
    meta: {
      titulo: 'Centros de costo, Periodos Fiscales y Departamentos'
    }
  },
  
]

const registro = [
  // OPERACIONES
  {
    path: '/Asientos',
    name: 'Asientos',
    component: () => import(/* webpackChunkName: "Asientos" */ '../views/contabilidad/Operaciones/Asientos'),
    meta: {
      titulo: 'Asientos Contables - Encabezado'
    }
  },
  {
    path: '/AsientosDetalles',
    name: 'AsientosDetalles',
    component: () => import(/* webpackChunkName: "AsientosDetalles" */ '../views/contabilidad/Operaciones/AsientosDetalles'),
    meta: {
      titulo: 'Asientos Contables - Detalles'
    }
  },
  {
    path: '/AsientosPorPlantilla',
    name: 'AsientosPorPlantilla',
    component: () => import(/* webpackChunkName: "AsientosPorPlantilla" */ '../views/contabilidad/Operaciones/AsientosPorPlantilla'),
    meta: {
      titulo: 'Asientos Por Plantilla'
    }
  },
  {
    path: '/AsientosRecurrentes',
    name: 'AsientosRecurrentes',
    component: () => import(/* webpackChunkName: "AsientosRecurrentes" */ '../views/contabilidad/Operaciones/AsientosRecurrentes'),
    meta: {
      titulo: 'Asientos Recurrentes'
    }
  },
  {
    path: '/AsientosDetallesRecurrentes',
    name: 'AsientosDetallesRecurrentes',
    component: () => import(/* webpackChunkName: "AsientosDetallesRecurrentes" */ '../views/contabilidad/Operaciones/AsientosRecurrentesDetalles'),
    meta: {
      titulo: 'Asientos Recurrentes - Detalles'
    }
  },
  
  {
    path: '/CargasExcel',
    name: 'CargasExcel',
    component: () => import(/* webpackChunkName: "CargasExcel" */ '../views/contabilidad/Operaciones/CargasExcel'),
    meta: {
      titulo: 'Cargas de Excel'
    }
  },
  
  
]

const reportes = [

  //REPORTES
  {
    path: '/BalanceComprobacion',
    name: 'BalanceComprobacion',
    component: () => import(/* webpackChunkName: "BalanceComprobacion" */ '../views/contabilidad/Reportes/BalanceComprobacion'),
    meta: {
      titulo: 'Balance de comprobación'
    }
  },
  {
    path: '/EstadoResultados',
    name: 'EstadoResultados',
    component: () => import(/* webpackChunkName: "EstadoResultados" */ '../views/contabilidad/Reportes/EstadoResultados'),
    meta: {
      titulo: 'Estado de Resultados'
    }
  },
  {
    path: '/BalanceGeneral',
    name: 'BalanceGeneral',
    component: () => import(/* webpackChunkName: "BalanceGeneral" */ '../views/contabilidad/Reportes/BalanceGeneral'),
    meta: {
      titulo: 'Balance General'
    }
  },
  {
    path: '/Renta',
    name: 'Renta',
    component: () => import(/* webpackChunkName: "Renta" */ '../views/contabilidad/Reportes/Renta'),
    meta: {
      titulo: 'Balance General'
    }
  },
  {
    path: '/MasivoRenta',
    name: 'MasivoRenta',
    component: () => import(/* webpackChunkName: "MasivoRenta" */ '../views/contabilidad/Reportes/MasivoRenta'),
    meta: {
      titulo: 'Resumen de Resultados'
    }
  },
  {
    path: '/CatalogoDeCuentas',
    name: 'CatalogoDeCuentas',
    component: () => import(/* webpackChunkName: "CatalogoDeCuentas" */ '../views/contabilidad/Reportes/CatalogoDeCuentas'),
    meta: {
      titulo: 'Catalogo de cuentas'
    }
  },
  {
    path: '/IvaMensual',
    name: 'IvaMensual',
    component: () => import(/* webpackChunkName: "IvaMensual" */ '../views/contabilidad/Reportes/IvaMensual'),
    meta: {
      titulo: 'Catalogo de cuentas'
    }
  },
]

const consultas = [
  {
    path: '/AsientosAplicados',
    name: 'AsientosAplicados',
    component: () => import(/* webpackChunkName: "AsientosAplicados" */ '../views/contabilidad/Operaciones/AsientosAplicados'),
    meta: {
      titulo: 'Asientos Aplicados'
    }
  },
  {
    path: '/HistoricoTipoCambio',
    name: 'HistoricoTipoCambio',
    component: () => import(/* webpackChunkName: "HistoricoTipoCambio" */ '../views/contabilidad/Parametros/HistoricoTipoCambio'),
    meta: {
      titulo: 'Histórico Tipo de Cambio'
    }
  },
  {
    path: '/ConsultaCuenta',
    name: 'ConsultaCuenta',
    component: () => import(/* webpackChunkName: "ConsultaCuenta" */ '../views/contabilidad/Reportes/ConsultaCuenta'),
    meta: {
      titulo: 'Consulta cuenta contable'
    }
  },
  
]

// PROCESOS
const procesos = [
  {
    path: '/CierreMensual',
    name: 'CierreMensual',
    component: () => import(/* webpackChunkName: "CierreMensual" */ '../views/contabilidad/Procesos/CierreMensual'),
    meta: {
      titulo: 'Cierre Mensual'
    }
  },
  {
    path: '/CierreAnual',
    name: 'CierreAnual',
    component: () => import(/* webpackChunkName: "CierreAnual" */ '../views/contabilidad/Procesos/CierreAnual'),
    meta: {
      titulo: 'Cierre Anual'
    }
  },
  {
    path: '/DiferencialCambiario',
    name: 'DiferencialCambiario',
    component: () => import(/* webpackChunkName: "DiferencialCambiario" */ '../views/contabilidad/Procesos/DiferencialCambiario'),
    meta: {
      titulo: 'Diferencial Cambiario'
    }
  },
]

const contabilidad = catalogos.concat(registro,reportes,consultas,procesos) 

export default {
  contabilidad
  
}
