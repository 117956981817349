const catalogos = [
  {
    path: '/RegistroProveedores',
    name: 'RegistroProveedores',
    component: () => import(/* webpackChunkName: "RegistroProveedores" */ '../views/CuentasPorPagar/Catalogo/RegistroProveedores'),
    meta: {
      titulo: 'Catálogo de Proveedores'
    }
  },
  {
    path: '/CategoriasProveedor',
    name: 'CategoriasProveedor',
    component: () => import(/* webpackChunkName: "CategoriasProveedor" */ '../views/CuentasPorPagar/Catalogo/CategoriasProveedor'),
    meta: {
      titulo: 'Categorías de Cuentas por Pagar'
    }
  },
  {
    path: '/ParametrosPorCompania',
    name: 'ParametrosPorCompania',
    component: () => import(/* webpackChunkName: "ParametrosPorCompania" */ '../views/CuentasPorPagar/Catalogo/ParametrosPorCompania'),
    meta: {
      titulo: 'Parametros Por Companias'
    }
  },
]

const registros = [
  {
    path: '/RelacionTransaccion',
    name: 'RelacionTransaccion',
    component: () => import('../views/CuentasPorPagar/Registro/RelacionTransaccion.vue'),
    meta: {
      titulo: 'Registro de Relacion de Facturas'
    }
  },
  {
    path: '/FacturaCxp',
    name: 'FacturaCxp',
    component: () => import('../views/CuentasPorPagar/Registro/FacturaCxp.vue'),
    meta: {
      titulo: 'Registro Factura Cuentas por pagar'
    }
  },
  {
    path: '/NotaCreditoCxp',
    name: 'NotaCreditoCxp',
    component: () => import('../views/CuentasPorPagar/Registro/NotaCreditoCxp.vue'),
    meta: {
      titulo: 'Registro Nota Credito Cuentas por pagar'
    }
  },
  {
    path: '/PagoRelacion',
    name: 'PagoRelacion',
    component: () => import('../views/CuentasPorPagar/Registro/PagosRelacion.vue'),
    meta: {
      titulo: 'Generación de relaciones de pago'
    }
  },
  {
    path: '/PagoFacturasCxp',
    name: 'PagoFacturasCxp',
    component: () => import('../views/CuentasPorPagar/Registro/PagoFacturasCxp.vue'),
    meta: {
      titulo: 'Generacion de pago de facturas registradas en CXP'
    }
  },
]

const procesos = [
  {
    path: '/cierreMensualCxp',
    name: 'cierreMensualCxp',
    component: () => import('../views/CuentasPorPagar/Procesos/CierreMensualCxp.vue'),
    meta: {
      titulo: 'Cierre Mensual Cuentas por pagar'
    }
  },
]

const consultas = [
  {
    path: '/ConsultaDocCxp',
    name: 'ConsultaDocCxp',
    component: () => import('../views/CuentasPorPagar/Consulta/ConsultaCxp.vue'),
    meta: {
      titulo: 'Consulta de documentos cuentas por pagar'
    }
  },
]

const reportes = [
  {
    path: '/PagoProveedores',
    name: 'PagoProveedores',
    component: () => import('../views/CuentasPorPagar/Reportes/PagoProveedores.vue'),
    meta: {
      titulo: 'Reporte de pago a Proveedores'
    }
  },
]

const cuentasPorPagar = catalogos.concat(registros, procesos, consultas, reportes)

export default {
  cuentasPorPagar,
}
