const configuraciones = [
  {
    path: '/Companias',
    name: 'Companias',
    component: () => import(/* webpackChunkName: "Companias" */ '../views/contabilidad/Parametros/Companias'),
    meta: {
      titulo: 'Compañias'
    }
  },
  {
    path: '/Contables',
    name: 'Contables',
    component: () => import(/* webpackChunkName: "Contables" */ '../views/contabilidad/Parametros/Contables'),
    meta: {
      titulo: 'Parametros Generales de Contabilidad'
    }
  },
  {
    path: '/Contables2',
    name: 'Contables2',
    component: () => import(/* webpackChunkName: "Contables" */ '../views/contabilidad/Parametros/Contables'),
    meta: {
      titulo: 'Parametros Generales de Contabilidad'
    }
  },
  {
    path: '/Contables3',
    name: 'Contables3',
    component: () => import(/* webpackChunkName: "Contables" */ '../views/contabilidad/Parametros/Contables'),
    meta: {
      titulo: 'Parametros Generales de Contabilidad'
    }
  },
  {
    path: '/Contables4',
    name: 'Contables4',
    component: () => import(/* webpackChunkName: "Contables" */ '../views/contabilidad/Parametros/Contables'),
    meta: {
      titulo: 'Parametros Generales de Contabilidad'
    }
  },
]


export default {
  configuraciones
  
}
