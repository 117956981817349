import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {
  Message,
  MessageBox,
  Notification
} from 'element-ui';

Vue.config.productionTip = false
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;

Vue.prototype.$convertToMoney = function (x) {
  x = Number(x)
  return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

Vue.prototype.$validateEmail = function(emailField){
  let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  if (reg.test(emailField) == false){
      return false
  }
  return true
}

Vue.prototype.$capitalize = function (text) {
  if (text) {
    return text.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
  } else {
    return ''
  }
  
}

Vue.prototype.$lowerCaseKeyObject = function (data){
  let objArray = []

  if (!Array.isArray(data)){
    let key, keys = Object.keys(data);
    let n = keys.length;
    let newobj={}
    while (n--) {
      key = keys[n]
      newobj[key.toLowerCase()] = data[key]
    }
    // console.log('es objeto')
    return newobj
  } else {
    objArray = data.map((item)=>{
      let key, keys = Object.keys(item);
      let n = keys.length;
      let newobj={}
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = item[key]
      }
      return newobj
    })
    // console.log('es array', objArray)
    return objArray
  }
},


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
