const operaciones = [
  {
    path: '/RegistroActivos',
    name: 'RegistroActivos',
    component: () => import(/* webpackChunkName: "RegistroActivos" */ '../views/ActivosFijos/Operaciones/RegistroActivos'),
    meta: {
      titulo: 'Registro de Activos'
    }
  },
  {
    path: '/ConsultaActivos',
    name: 'ConsultaActivos',
    component: () => import(/* webpackChunkName: "ConsultaActivos" */ '../views/ActivosFijos/Operaciones/ConsultaActivos'),
    meta: {
      titulo: 'Consulta de Activos'
    }
  },
]

const parametros = [
  {
    path: '/ParametrosActivosFijos',
    name: 'ParametrosActivosFijos',
    component: () => import(/* webpackChunkName: "ParametrosActivosFijos" */ '../views/ActivosFijos/Parametros/ParametrosActivosFijos'),
    meta: {
      titulo: 'Parametros Activos Fijos'
    }
  },
  {
    path: '/CategoriasAF',
    name: 'CategoriasAF',
    component: () => import(/* webpackChunkName: "CategoriasAF" */ '../views/ActivosFijos/Parametros/CategoriasAF'),
    meta: {
      titulo: 'Parametros Activos Fijos'
    }
  },
]

const reportes = [
  {
    path: '/AuxDepreciacion',
    name: 'AuxDepreciacion',
    component: () => import(/* webpackChunkName: "AuxDepreciacion" */ '../views/ActivosFijos/Reportes/AuxDepreciacion'),
    meta: {
      titulo: 'Auxiliar de Depreciación Acumulada'
    }
  },
  {
    path: '/ActivosPorCategoria',
    name: 'ActivosPorCategoria',
    component: () => import(/* webpackChunkName: "ActivosPorCategoria" */ '../views/ActivosFijos/Reportes/ActivosPorCategoria'),
    meta: {
      titulo: 'Activos Por Categoría'
    }
  },
  {
    path: '/GeneralDeActivos',
    name: 'GeneralDeActivos',
    component: () => import(/* webpackChunkName: "GeneralDeActivos" */ '../views/ActivosFijos/Reportes/GeneralDeActivos'),
    meta: {
      titulo: 'General de Activos'
    }
  },
]

const procesos = [
  {
    path: '/DepreciacionMensual',
    name: 'DepreciacionMensual',
    component: () => import( '../views/ActivosFijos/Procesos/DepreciacionMensual.vue'),
    meta: {
      titulo: 'Depreciación Mensual'
    }
  },
  {
    path: '/AFCierreMensual',
    name: 'AFCierreMensual',
    component: () => import( '../views/ActivosFijos/Procesos/AFCierreMensual.vue'),
    meta: {
      titulo: 'Cierre Mensual Activos Fijos'
    }
  }
]

const activosFijos = operaciones.concat(parametros, reportes, procesos)

export default {
  activosFijos
}
