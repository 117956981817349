<template>
  <v-app>
    <v-main class="main-content">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created(){
    
  }
};
</script>

<style>
.main-content{
  background-color: #f8f9fa;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 22px !important;
  padding: 0 10px !important;
}
.texto-auto-cuentas{
  font-size: 12px !important;
  
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
  min-height: 33px !important;
}

.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 33px !important; 
}

.v-pagination__item {
  font-size: 0.85rem !important;
  height: 24px !important;
  min-width: 24px !important;
}

.v-pagination__navigation {
  height: 24px !important;
  width: 24px !important;
}

.v-dialog.custom-v-dialog.v-dialog--active.v-dialog--persistent{
  border-radius: 24px !important;
}

/* INPUTS DE SOLO NUMEROS CON FORMATO NUMERICO */
.input-totals .v-input__control .v-text-field__details{
  display: none !important;
}
.input-totals .v-input__control .v-input__slot .v-text-field__slot{
  font-size: 12px !important;
}
.input-totals .v-input__control .v-input__slot{
  padding: 0 0.5rem 0 0.5rem !important;
}
.input-totals .v-input__control .v-input__slot input {
  text-align: end !important;
  max-height: 28px !important;
}
</style>
