import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import {
  axiosC
} from "../api/axiosBd"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    endDate: localStorage.getItem('endDate') || null,
    name: localStorage.getItem('name') || null,
    toggleSidebar: true,
    balanceComprobacion: false,
    estadoResultado: false,
    balanceGeneral: false,
    renta: false,
    depreciacionAcumulada: false,
    cierreMensual: false,
    cierreAnual: false,
    diferencialCambiario: false,
    depreciacionMensual: false,
    afCierreMensual: false,
    catalogoCuentas: false,
    masivoRenta: false,
    ivaMensual: false,
    activosPorCategoria: false,
    GeneralDeActivos: false,
    asientosPorPlantilla: false,
    cierreMensualCxp: false,
    pagoProveedores: false,

    acc001: [],
    acc002: [],
    DIR_DEDUCIBLES_RENTA: [],
    acc005: [],
    acc026: [],
    tipoDocumento: [
      {id: '1', text: 'Cedula Fisica'},
      {id: '2', text: 'Cedula Juridica'},
      {id: '3', text: 'DIMEX'},
      {id: '4', text: 'NITE'},
    ],
    monedas: [
      {id: "COL", code: "₡ Colones"},
      {id: "DOL", code: "$ Dolares"},
    ],
    // ACCS01, ACCS02, ACCS03, ACCS04, ACCS05, ACCS06, ACCS07, ACCDES

    asientoDetalles: JSON.parse(localStorage.getItem('asientoDetalles')) || null,
    asientoDetallesR: JSON.parse(localStorage.getItem('asientoDetallesR')) || null,
  },
  mutations: {
    setAccessToken(state, token) {
      state.endDate = token.endDate
      localStorage.setItem('endDate', token.endDate)
      state.rowKey = token.rowKey
      localStorage.setItem('rowKey', state.rowKey )
      state.name = token.name
      localStorage.setItem('name', state.name )
    },
    setDestroyTokens(state) {
      state.endDate = null
      state.rowKey = null
      state.name = null
      localStorage.removeItem('endDate')
      localStorage.removeItem("rowKey")
      localStorage.removeItem("name")
    },
    mutActiveSidebar(state) {
      state.toggleSidebar = !state.toggleSidebar
    },
    mutBoards(state, data){
      state[data.board] = data.data
    },
    mutAsientoDetalles(state, data) {
      state.asientoDetalles = data
      localStorage.setItem('asientoDetalles', JSON.stringify(data))
    },
    mutAsientoDetallesR(state, data) {
      state.asientoDetallesR = data
      localStorage.setItem('asientoDetallesR', JSON.stringify(data))
    }

  },
  actions: {
    accDestroyAll({commit}, outside){
      commit('setDestroyTokens')
      if (outside) router.push({name:'Login'})
      console.log('borre el local storage')
    },
    accSetTokens({commit}, data){
      commit("setAccessToken",data)
    },
    accAsientoDetalles({commit}, data){
      commit('mutAsientoDetalles', data)
    },
    accAsientoDetallesR({commit}, data){
      commit('mutAsientoDetallesR', data)
    },
    actActiveSidebar({commit}, data) {
      if (data){
        commit('mutActiveSidebar', data)
      } else {
        commit('mutActiveSidebar', false)
      }
    },
  
    async logoutUser({ commit, state, dispatch}) {
      let res = {
        state: true,
        message: "",
      }
      await dispatch('verifyToken')
      try {
        const response = axiosC({
          method: 'DELETE',
          url: `/current_user/deleteSeasson`,
          data: {key: state.rowKey}
        })
        if (response.status >= 400 ) {
          throw new Error('ERROR EN LOGOUT')
        } else {
          res.state = true
          commit('setDestroyTokens')
          localStorage.clear()
        }

      } catch (error) {
        console.log(error.response)
        localStorage.clear()
        res.state = false
        res.message = error.response ? error.response.data.message : error
      }
      router.push({name: 'Login'})
      return res
    },
    async verifyToken({ state,commit }) {
      let res = {
        state: true,
        message: "",
      }

      if (state.endDate) {
        if (state.endDate*1000 < new Date().getTime()) {
          try {
            const response = await axiosC.post("auth/refresh/")
            console.log('mande el refresh')
            if (response.status > 400 ) {
              router.push('Login')
              throw new Error('Se vencio el token, hay que ir al login')
            }
            commit("setAccessToken", response.data)
          } catch (err) {
            console.log('error en refresh aqui', err.response ? err.response.data : err)
            res.state = false
            res.message = err.response ? err.response.data.message.mensaje : 'error en refresh token, tienda.'

            commit('setDestroyTokens')
            // router.push('Login')
            // if (err.response && err.response.status == 401) {
            //   res.message = `Verify token: Unauthorized user, please login.`
            // } else {
            //   res.message = `Verify token:  ${ err.response ? err.response.data.message : 'No server response'}`
            // }
          }
        }
      } else {
        console.log('error aqui afuera verificacion local storage')
        res.state = false
        res.message = `Verify token: Can't get access token.`
      }
      return res
    },
    async getData({ state,commit, dispatch }, obj){
      // Para mandar parametros (fields, where) que permitan organizar y filtrar respectivamente se enviarán así:
      // '&fields=field1&fields=field2&fields=field3...&where[field1]=A&where[field2]=2022-03-09&where[field3]=16...'
      try {
        const res = await dispatch('verifyToken')
        if (res.state) {
          const response = await axiosC({
            method: 'GET',
            url: `/query_methods/list?board=${obj.board}${obj.params ? obj.params : ''}`
          })

          commit('mutBoards',{board: obj.board, data:response.data.data})

        } else{
          commit('setDestroyTokens')
          localStorage.clear()
          router.push({name:'Login'})
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  modules: {
  }
})
